@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background-color: #16181c;
  color: #ffffff;
  overflow-x: hidden;
  padding-right: 0px;
}
.h2tag {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.h5tag {
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}
.ptag {
  font-weight: 400;
  font-size: 14px;
  color: #8993a4;
}
.h6tag {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}
.voilet_text {
  color: #dc4e16;
}
.primary_btn {
  background: #dc4e16;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  color: #f0f0f0;
  transition: 0.25s;
  text-decoration: none;
}
.dropdown-toggle.btn.btn-primary {
  background: #dc4e16;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  color: #f0f0f0;
  transition: 0.25s;
  text-decoration: none;
}
.primary_btn img {
  filter: brightness(0) invert(1) !important;
}
.primary_btn:hover img {
  filter: none !important;
}
.primary_btn:hover {
  border: 1px solid #dc4e16;
  color: #dc4e16;
  background: transparent;
}
.white_box {
  background: #131519;
  border: 1px solid #202228;
  border-radius: 12px;
  padding: 36px;
  margin-bottom: 30px;
}
.nav_bar {
  background: #131519;
}
.nav_bar .box1 {
  padding: 12px;
  border-radius: 3px;
}
.nav_bar .offcanvas-body {
  align-items: center;
  justify-content: end;
}
.nav_bar .box1 .ptag {
  margin-bottom: 0;
}
.nav_bar .box1 .h2tag {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  line-height: 13px;
}
.dflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box1 {
  background: #202228;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  height: 100%;
}
.box1 .h2tag {
  margin-bottom: 0;
}
.box1 .h5tag {
  margin-bottom: 0;
}
.table_content .rdt_TableCol {
  background: #3a3c42;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #3b394a;
  border-bottom: 1px solid #3a3c42;
}
.table_content .rdt_TableCol:first-child {
  border-top-left-radius: 6px;
}
.table_content .rdt_TableCol:last-child {
  border-top-right-radius: 6px;
}
.table_content .rdt_TableHeadRow {
  border-bottom: 1px solid transparent;
}
.table_content .rdt_TableCell {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.table_content .rdt_TableRow {
  border-bottom: 1px solid #3a3c42 !important;
}
.table_content .rdt_TableRow:hover {
  background-color: #f1f1f1;
}
.table_content .rdt_Pagination {
  background: #202228;
  color: #ffffff;
}
.table_content .rdt_Pagination button {
  color: rgb(255 255 255 / 54%);
  fill: rgb(255 255 255 / 54%);
}
.table_content .rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%);
  fill: rgb(255 255 255 / 18%);
}
.custom_table thead th,
.custom_table tbody td {
  font-weight: 400;
  font-size: 14px;
  background: #202228;
  color: #fff;
  border-color: #3a3c42;
  padding: 12px 16px;
}
.upload-container {
  height: 100%;
}
.upload-area {
  background: #202228;
  border: 1px dashed #3a3c42;
  border-radius: 6px;
  padding: 36px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.upload-area i {
  font-size: 50px;
  color: #007bff;
}

.upload-area p {
  font-size: 18px;
  color: #555;
}

.upload-area input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* .file-list {
  margin-top: 20px;
} */

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.file-details {
  flex: 1;
}

.file-details span {
  display: block;
}

.dotted_box {
  border: 1px dashed #3a3c42;
  border-radius: 6px;
  padding: 24px;
  background: #202228;
}
.dotted_box .white_box {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.dotted_box .white_box:last-child {
  margin-bottom: 0;
}
.custom-progress {
  height: 10px;
  background-color: #3a3c42;
}
.custom-progress .progress-bar {
  background-color: #dc4e16;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.offcanvas-header .btn-close {
  box-shadow: none;
}
.modal-content {
  background-color: #131519;
}
.modal-header {
  border-color: #3a3c42;
}
.btn-close {
  filter: invert(1);
  box-shadow: none !important;
}
.walletButtonConnect {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.walletButtonConnect button {
  flex-grow: 1;
  padding: 15px;
}
.walletButtonConnect button img {
  width: auto;
  margin-bottom: 15px;
  max-width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.table_content div {
  background: #202228 !important;
  color: #ffffff;
}
@media (max-width: 1199px) {
  .box1 {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 991px) {
  .nav_bar .box1 {
    margin-bottom: 20px;
  }
  .nav_bar .box1 .dflex {
    flex-direction: row;
  }
  .nav_bar form {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .nav_bar .primary_btn {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .primary_btn {
    padding: 5px 22px;
    font-size: 14px;
  }
  .dotted_box .white_box .dflex .user_count {
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
  }
  .h2tag {
    text-align: center;
  }
  .ptag {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }
  .white_box {
    padding-left: 12px;
    padding-right: 12px;
  }
  .dropdown-toggle.btn.btn-primary {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 575px) {
  .primary_btn {
    padding: 5px 18px;
    font-size: 13px;
  }
  .dotted_box .white_box .dflex {
    align-items: flex-start;
  }
  .user_count .ptag {
    font-size: 13px;
    white-space: nowrap;
  }
  .user_count .h6tag {
    line-height: 15px;
  }
  .box1 .dflex {
    flex-direction: column;
    gap: 10px;
  }
  .navbar-toggler-icon {
    filter: invert(1);
  }
  .offcanvas {
    background: #16181c;
  }
}
